<!-- @format -->

<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Personal Data</b-card-title>
      <!-- <b-card-text class="font-small-2 mr-25 mb-0"> Updated {{ new Date().toLocaleDateString() }} </b-card-text> -->
    </b-card-header>
    <b-card-body class="statistics-body">
  <b-row class="d-flex" style="align-items: center;">
    <b-col v-for="(item,index) in data"  :key="index" xl="6" sm="6" :class="[item.customClass, 'summary-item']">
      <b-media no-body style="margin-bottom: 10px;">
        <b-media-aside class="mr-2 summary-avatar">
  <img :src="iconPath[item.subtitle]" style="width:35px; height:35px;" />
</b-media-aside>

            <b-media-body class="my-auto">
              <h4 class="summary-title dashboard_active font-weight-bolder mb-0" @click="toPage(item.url||'')">
                {{ switchOn?'xxx':item.title ? `${String(item.title).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : item.title }}
              </h4>
              <b-card-text class="summary-subtitle mb-0 dashboard_active">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    switchOn:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      path: '',
      iconPath: {
        'Baseshop TP': require('@/assets/1.jpg'),
        'Inforce TP': require('@/assets/2.jpg'),
        'Pending TP': require('@/assets/3.jpg'),
        'Paid Commission': require('@/assets/4.jpg'),
        'Total Cases': require('@/assets/5.jpg'),
        'Total Members': require('@/assets/6.jpg'),
      }
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   axiosIns({
    //     url: 'user/userinfo',
    //     method: 'get',
    //   }).then(res => {
    //     const { code_url } = res.data
    //     this.path = code_url
    //   })
    // })
    this.dataHandle()
  },
  methods: {
    toPage(url) {
      // this.$router.push({ name })
      if(url){
        this.$router.push({ path:url })
      }
    },
    dataHandle(){
      this.data.forEach(item=>{
        switch (item.subtitle){
          case 'Inforce TP':
            item.url = '/vip/insurance-policy?inforce=Active';
            break;
          case 'Pending TP':
            item.url = '/vip/insurance-policy?pending=Reviewed';
            break;
          case 'Paid Commission':
            item.url = '/vip/Commission_record';
            break;
          case 'Total Members':
            item.url = '/vip/team';
            break;
          case 'Total Cases':
            item.url = '/vip/insurance-policy';
            break;
          case 'Baseshop TP':
            item.url = '/vip/analytics?tabIndex=1';
            break;
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.summary-img {
  width: 120px;
}
@media (min-width: 920px) and  (max-width: 1600px) {
  .summary-item {
    padding-left: 0;
  }

  .summary-avatar {
    margin-right: 0.7rem !important;
  }
}
@media (min-width: 1440px) {
  .summary-title {
    font-size: 1.8rem;
  }
  .summary-subtitle {
    font-size: 1.3rem;
  }
  .summary-img {
    width: 80%;

  }
}
</style>
