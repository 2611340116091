<!-- @format -->

<template>
  <b-card class="card-browser-states">
    <!--    <b-card-header>-->
    <!--      <div>-->
    <!--        <b-card-title>Insurance Company</b-card-title>-->
    <!--        &lt;!&ndash; <b-card-text class="font-small-2">Counter August 2020</b-card-text> &ndash;&gt;-->
    <!--      </div>-->
    <!--    </b-card-header>-->
    <b-row class="mb-2">
      <b-col xl="3" sm="6" md="6" v-for="(browser, index) in data" :key="index" class="browser-states mb-2" style="cursor: pointer">
        <div style="width: 100%" class="d-flex" @click="toUrl(browser)">
          <BAvatar :src="browser.logo" alt="browser img" size="60" />
          <h6 class="align-self-center my-auto ml-1" style="line-height: 40px; white-space: nowrap">{{ browser.title }}</h6>
        </div>

        <div class="d-flex align-items-center">
          <feather-icon icon="ChevronsUpIcon" size="26" v-if="index == 0" style="color: red" />
          <!-- <span class="font-weight-bold text-body-heading mr-1 ml-1">{{ browser.num }}</span> -->
          <!-- <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="chartData[index].series"
          /> -->
        </div>
      </b-col>
    </b-row>
    <!--/ body -->
  </b-card>
</template>

<script>
import { BAvatar, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BAvatar,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
    FeatherIcon,
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      chartData: [],
      chartClone: {},
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      browserData: [],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  methods: {
    toUrl(data) {
      // 判断合法域名 http www,如果符合则跳转
      if (data.url.indexOf('http') > -1) {
        window.open(data.url)
      } else {
        window.open(`http://${data.url}`)
      }
    },
  },
}
</script>

<style>
.card-browser-states .browser-states:not(:first-child) {
  margin-top: 0px !important;
  cursor: pointer;
}
</style>
