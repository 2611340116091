<template>
  <b-card v-if="info.series[0].percent" no-body>
    <!-- title -->
    <b-card-header class="pb-0">
      <b-card-title style="margin-top: 30px;padding-left: 60px;"><h2>{{ datas.title }}</h2></b-card-title>
    </b-card-header>
    <!--/ title -->

    <b-card-body style="align-items: center; display: flex">
      <b-row style="width: 100%">
        <b-col sm="4" class="d-flex flex-column flex-wrap text-center">
          <div
              style="
              display: flex;
              flex-flow: column;
              justify-content: space-around;
              height: 100%;
            "
          >
            <div class="text-center">
              <span
                  class="font-large-1 font-weight-bold dashboard_active"
                  @click="toPage('vip_insurance-policy')"
              >
                {{ switchOn?'xxx':formatData(info.series[0].total_order) }}
              </span>
              <b-card-text class="mb-50 dashboard_active">
                Total Policy
              </b-card-text>
            </div>
            <div class="text-center">
              <span
                  class="font-large-1 font-weight-bold dashboard_active"
                  @click="toPage('vip_insurance-policy')"
              >
                {{ switchOn?'xxx':formatData(info.series[0].not_ok_order) }}
              </span>
              <b-card-text class="mb-50 dashboard_active">
                Pending Policy
              </b-card-text>
            </div>
          </div>
        </b-col>
        <!-- chart -->
        <b-col sm="4" class="d-flex justify-content-center">
          <!-- apex chart -->
          <vue-apex-charts
              type="radialBar"
              height="270"
              :options="supportTrackerRadialBar.chartOptions"
              :series="[percent]"
          />
        </b-col>
        <b-col sm="4" class="d-flex flex-column flex-wrap text-center">
          <b-row
              style="
              display: flex;
              flex-flow: column;
              justify-content: space-around;
              height: 100%;
              width: 100%;
            "
          >
            <div class="text-center">
              <span
                  class="font-large-1 font-weight-bold dashboard_active"
                  @click="toPage('vip_insurance-policy')"
              >
                {{ switchOn?'xxx':formatData(info.series[0].is_ok_order) }}
              </span>
              <b-card-text class="mb-50 dashboard_active">
                Inforce Rate
              </b-card-text>
            </div>
            <div class="text-center">
              <span
                  class="font-large-1 font-weight-bold dashboard_active"
                  @click="toPage('vip_insurance-policy')"
              >
                {{ switchOn?'xxx':formatData(info.series[0].my_team_order) }}
              </span>
              <b-card-text class="mb-50 dashboard_active">
                Total Commission <br> Earned Policy
              </b-card-text>
            </div>
          </b-row>
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  data() {
    return {
      datas: {
        title: "Total Business",
        lastDays: ["Last 28 Days", "Last Month", "Last Year"],
        totalTicket: 163,
        newTicket: 29,
        openTicket: 63,
        responseTime: 1,
        supportTrackerRadialBar: {
          series: [this.info.series[0].percent],
        },
      },
      percent:0,
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%",
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem",
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem",
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ["Inforce Rate"],
        },
      },
    };
  },
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  watch:{
    switchOn:{
      handler(newv){
        console.log(newv);
        if(newv){
          this.percent = 'xxx'
        }else{
          this.percent = this.info.series[0].percent;
        }
      },
      immediate:true,
      deep:true
    },
    info:{
      handler(newv){
        if(this.switchOn){
          this.percent = 'xxx'
        }else{
          this.percent = newv.series[0].percent;
        }
      },
      immediate:true,
      deep:true
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    switchOn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toPage(name) {
      this.$router.push({ name });
    },
    formatData(data) {
      return data
          ? `${String(data).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : data;
    },
  },
};
</script>
