<template>
  <b-card v-if="data" no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col md="10" class="revenue-report-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">Revenue Report</h4>
          <!-- <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span
                class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Revenue</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span
                class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Fees</span>
            </div>
          </div> -->
        </div>

        <!-- chart -->
        <!-- <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="data.revenueReport.series"
        /> -->
        <div class="echart" id="mychart_sr" :style="myChartStyle" />
      </b-col>

      <b-col md="2" class="budget-wrapper">
        <b-dropdown
          :text="dText"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item v-for="year in data.years" :key="year" @click="ccDrop(year)">
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>

        <!--        <h2 class="mb-25">${{ data.price }}USD</h2>-->
        <!--        <div style="margin-bottom: 100px" />-->
        <!--        <vue-apex-charts-->
        <!--          id="budget-chart"-->
        <!--          type="line"-->
        <!--          height="80"-->
        <!--          :options="budgetChart.options"-->
        <!--          :series="data.budgetChart.series"-->
        <!--        />-->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import * as echarts from 'echarts'
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import axiosIns from '@/libs/axios'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    ECharts,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {

  },
  data() {
    return {
      data: {
        years: ['2020', '2019', '2018'],
        price: '25,852',
        budget: '56,800',
        revenueReport: {
          series: [
            {
              name: '收入',
              data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
            },
            {
              name: '费用',
              data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
            },
          ],
        },
        budgetChart: {
          series: [
            {
              data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
            },
            {
              data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
            },
          ],
        },
      },
      dText: new Date().getFullYear(),
      myChartStyle: { float: 'left', width: '100%', height: '400px' }, // 图表样式
      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
            ],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
        },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },
      option: {
        color: ['#a5f8ce', '#60f2cb', '#26dbc8'],
        title: {
          text: 'Stacked Area Chart',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: ['总应收', '已付', '未付'],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
          },
        ],
        yAxis: [
          {
            axisTick: {
              // y轴刻度线
              show: false,
            },
            axisLine: {
              // y轴
              show: false,
            },
            type: 'value',
          },
        ],
        series: [
          {
            name: '总应收',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: [1210, 132, 101, 134, 90, 230, 210],
          },
          {
            name: '已付',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: [2230, 12182, 191, 2324, 1290, 230, 310],
          },
          {
            name: '未付',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series',
            },
            data: [150, 232, 23101, 1154, 1930, 3230, 410],
          },
        ],
      }

    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initEcharts()
      // this.fetchData()
    })
  },
  methods: {
    initEcharts() {
      // 基本柱状图
      // this.yData[0].value = this.$store.state.ddd.percent;
      axiosIns({
        url: 'index/year-smoney',
        method: 'GET',
        params: {
          year: this.dText
        }
      }).then(res => {
        this.option.series[0].data = res.data.all_money.data
        this.option.series[2].data = res.data.pay_money.data
        this.option.series[1].data = res.data.not_pay_money
        const mychart = echarts.init(document.getElementById('mychart_sr'))
        mychart.setOption(this.option)
        // 随着屏幕大小调节图表
        window.addEventListener('resize', () => {
          mychart.resize()
        })
        // console.log(res)
      })
    },
    fetchData() {
      axiosIns({
        url: 'index/year-smoney',
        method: 'GET',
        params: {
          year: this.dText
        }
      }).then(res => {
        this.option.series[0].data = res.data.all_money.data
        this.option.series[2].data = res.data.pay_money.data
        this.option.series[1].data = res.data.not_pay_money
        const mychart = echarts.init(document.getElementById('mychart_sr'))
        mychart.setOption(this.option)
        // 随着屏幕大小调节图表
        window.addEventListener('resize', () => {
          mychart.resize()
        })
        this.$forceUpdate()
      })
    },
    ccDrop(val) {
      this.dText = val
      this.fetchData()
    }
  },
}
</script>
