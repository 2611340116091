<!-- @format -->

<template>
  <b-card v-if="data">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1"> Commission for the month </b-card-title>
        <div class="font-small-2">Total this month</div>
        <h5 class="mb-1">${{ data.data.allTotal }}</h5>
        <b-card-text class="text-muted font-small-2">
          <span class="font-weight-bolder">{{ data.data.percent }}%</span><span>Increase over last month</span>
          <span v-if="data.data.percent < 0">Go for it!</span>
          <span v-else>Keep up the good work!</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <CCCC :series="series" :options="apexChatDataOptions" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from 'bootstrap-vue'
import CCCC from '@/views/charts-and-maps/charts/apex-chart/ApexRadialBarChart.vue'
import apexChatData from '@/views/charts-and-maps/charts/apex-chart/apexChartData.js'

export default {
  components: {
    CCCC,
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    series: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      apexChatDataOptions: null,
      apexChatData,
      myChartStyle: { width: '100%', height: '400px' }, // 图表样式
    }
  },

  mounted() {
    this.$nextTick(() => {
      console.log(this.data)
      setTimeout(() => {
        this.init()
      }, 1000)
    })
  },
  methods: {
    init() {
      this.apexChatDataOptions = apexChatData.radialBarChart.chartOptions
      this.apexChatDataOptions.labels = ['Personal', 'Level', 'Team']
      this.$forceUpdate()
    },
  },
}
</script>
