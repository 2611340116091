<!-- @format -->

<template>

  <section style="padding: 10px">

    <b-row class="match-height">
      <b-col md="12">
        <b2 :data="data.userRankInfo" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col xl="6" md="6" sm="12">
        <!--        {{data}}-->
        <l1 :switchOn="switchOn"></l1>
      </b-col>
      <b-col xl="6" md="6" sm="12">
        <!-- {{ data.userInfo }} -->
        <l2 :switchOn="switchOn" :data="data.statisticsItems" />
      </b-col>
    </b-row>



    <b-row class="match-height">
      <b-col xl="12" sm="12">
        <b-row class="match-height">
          <b-col lg="5" sm="12">
            <!-- <ll1 :data="data.statisticsOrder" v-if="data.statisticsOrder.series[0].data.length > 0" /> -->
            <b-card>
              <b-card-header
                  style="display: flex; flex-direction: row-reverse; padding: 0"
              >
                <!-- <b-dropdown variant="link" toggle-class="p-0" no-caret>
                  <template #button-content>
                    <feather-icon class="text-body" icon="MoreVerticalIcon" />
                  </template>
                  <b-dropdown-item @click="getTpsdata(1)">
                    Last 7 Days
                  </b-dropdown-item>
                  <b-dropdown-item @click="getTpsdata(2)">
                    Last Month
                  </b-dropdown-item>
                  <b-dropdown-item @click="getTpsdata(3)">
                    Last Year
                  </b-dropdown-item>
                </b-dropdown> -->
              </b-card-header>
              <div class="pie-text text-center">
                <h2
                    class="font-weight-bolder dashboard_active"
                    @click="toPage('vip_Commission_record')"
                >
                  {{
                    switchOn?'xxx':allTotal
                        ? `$${String(allTotal).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                        )}`
                        : allTotal
                  }}
                </h2>
                <span class="font-weight-bold dashboard_active">All Total</span>
              </div>
              <div style="height: 300px">
                <AppEchartDoughnut
                    :isJump="true"
                    :series="[
                    {
                      name: 'Visit source',
                      type: 'pie',
                      radius: ['50%', '70%'],
                      avoidLabelOverlap: false,
                      label: {
                        show: true,
                      },
                      labelLine: {
                        show: true,
                      },
                      data: tpDData,
                    },
                  ]"
                    v-if="tpDData.length"
                />
              </div>
            </b-card>
          </b-col>
          <!-- <b-col lg="3" md="12">
            <ll2 :data="data.statisticsProfit" v-if="data.statisticsProfit.series[0].data.length > 0" />
          </b-col> -->
          <b-col lg="7" md="12">
            <AnalyticsAvgSessionsTracker
                :info="data.TrackerData"
                v-if="data.TrackerData.series[0].percent"
                :switchOn="switchOn"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- <AnalyticsAvgSessionsTracker :data="data.TrackerData" /> -->
      <!-- <b-col xl="12" sm="12">
        <rc1  />
      </b-col> -->
    </b-row>

    <b-row class="match-height" lg="12">
      <b-col lg="8" md="8" xl="8">
        <ecommerce-member :data="data.todayBirthUser" />
      </b-col>
      <b-col lg="4" md="4" xl="4">
        <l3 :switchOn="switchOn" :data="data.members" />
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BFormCheckbox } from "bootstrap-vue";

import axios from "@/libs/axios";
import { getUserData } from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import { ref, onMounted, getCurrentInstance } from "@vue/composition-api";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceMember from "./EcommerceMember.vue";
import AnalyticsAvgSessions from "./AnalyticsAvgSessions.vue";
import AnalyticsAvgSessionsTracker from "./AnalyticsSupportTracker.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import StatisticCardWithBarChart from "@core/components/statistics-cards/StatisticCardWithBarChart.vue";
import { kFormatter,addStr } from "@core/utils/filter";

import l1 from "./l1.vue";
import l2 from "./l2.vue";
import l3 from "./l3.vue";
import ll1 from "./ll1.vue";
import ll2 from "./ll2.vue";
import ll3 from "./ll3.vue";
import rc1 from "./rc1.vue";
import b1 from "./b1.vue";
import b2 from "./b2.vue";
import {mapState} from "vuex";
export default {
  components: {
    l1,
    l2,
    l3,
    ll1,
    ll2,
    ll3,
    rc1,
    b1,
    b2,
    BRow,
    BCol,
    EcommerceMember,
    EcommerceOrderChart,
    AnalyticsAvgSessions,
    AnalyticsAvgSessionsTracker,
    BCard,
    AppEchartDoughnut,
    StatisticCardWithAreaChart,
    BCardHeader,
    StatisticCardWithBarChart,
    BFormCheckbox,
  },
  computed:{
    ...mapState({
      switchOn:state=>state.switchOn
    })
  },
  data() {
    return {
      ya: null,
      yb: null,
      yan: 0,
      yab: 0,
      superbase_tp: "",
      baseshop_tp: "",

      main_all: {},
      address: "",
      password_hash: "",
      real_name: "",
      gender: 0,
      sex: [
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 2,
        },
      ],
      tpDData: [],
      allTotal: 0,
      imgurl: "",
      data: {
        statisticsOrder: {
          series: [
            {
              name: "",
              effect_tp: "",
              data: [],
            },
          ],
        },

        statisticsProfit: {
          series: [
            {
              name: "",
              effect_tp: "",
              data: [],
            },
          ],
        },
        sessionsData: {
          series: [],
        },
        TrackerData: {
          series: [],
        },
        TopData: {},
        userInfo: {},
        congratulations: {},
      },
      childUserData: [],
      baifenData: [],
      userDatas: JSON.parse(localStorage.getItem("userData")),
      id: JSON.parse(localStorage.getItem("first_login")),
    };
  },

  setup() {
    // eslint-disable-next-line
    // const { proxy } = getCurrentInstance();

    const refInputEl = ref(null);
    const imgdata = ref(null);
    const childUserData = ref(null);
    const baifenData = ref(null);
    onMounted(() => {
      useJwt.index_head().then((response) => {
        childUserData.value = response.data;
      });
      useJwt.baifen().then((response) => {
        baifenData.value = response.data;
      });
    });
    return {
      childUserData,
      baifenData,
      refInputEl,
      imgdata,
    };
  },

  mounted() {
    this.$nextTick(() => {
      // this.$http.get("/ecommerce/data", {}).then((response) => {
      // console.log(response, 'response')
      // this.data = response
      // const userData = getUserData()
      // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      // });
      if (this.id === 1) {
        this.tanchuang();
        this.userDatas.first_login = 0;
        localStorage.setItem("first_login", this.userDatas.first_login);
      }
      const indexDashData = {
        todayBirthUser: [],
        congratulations: {},
        members: [
          {
            icon: "UsersIcon",
            color: "light-primary",
            title: "",
             subtitle: "Inactive Members",
            //subtitle: "Total Members",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "UsersIcon",
            color: "light-primary",
            title: "",
            subtitle: "Active Members",
            customClass: "mb-2 mb-sm-0",
          },
        ],
        statisticsItems: [
          {
            icon: "UsersIcon",
            color: "primary",
            title: "",
            subtitle: "Baseshop TP",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "UsersIcon",
            color: "info",
            title: "",
            subtitle: "Paid Commission",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "BoxIcon",
            color: "light-danger",
            title: "",
            subtitle: "Inforce TP",
            customClass: "mb-2 mb-sm-0",
          },

          {
            icon: "UserIcon",
            color: "light-info",
            title: "",
            subtitle: "Total Cases",
            customClass: "mb-2 mb-xl-0",
          },

          {
            icon: "UsersIcon",
            color: "success",
            title: "",
            subtitle: "Pending TP",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "TrendingUpIcon",
            color: "light-primary",
            title: "",
            subtitle: "Total Members",
            customClass: "mb-2 mb-xl-0",
          },
        ],
        statisticsOrder: {
          series: [
            {
              name: "2020",
              effect_tp: "",
              data: [],
            },
          ],
        },
        statisticsProfit: {
          series: [
            {
              data: [],
              no_effect_tp: "",
            },
          ],
        },
        sessionsData: {
          series: [
            {
              data: [],
              no_data: [],
              total: "",
              list: [
                {
                  name: "123",
                  data: [],
                },
              ],
            },
          ],
        },
        TrackerData: {
          series: [
            {
              percent: [],
              not_ok_order: "",
              is_ok_order: "",
              my_team_order: "",
              total_order: "",
            },
          ],
        },
        TopData: [],
        earningsChart: {
          data: {
            allTotal: 0,
            percent: undefined,
          },
          series: [53, 16, 31],
        },
        revenue: {},
        userRankInfo: {},
        threeCircle: {
          series: [],
        },
      };
      this.$nextTick(() => {
        setTimeout(() => {
          axios
              .get("index/index", {
                // baseURL: 'http://127.0.0.1:8000/backend',
              })
              .then((response) => {
                // console.log(response.data)
                indexDashData.TrackerData.series[0].total_order =
                    response.data.orderInfo.total_business; // 个人订单总订单数
                indexDashData.TrackerData.series[0].not_ok_order =
                    response.data.orderInfo.not_ok_order;
                indexDashData.TrackerData.series[0].is_ok_order =
                    response.data.orderInfo.inforce_business;
                indexDashData.TrackerData.series[0].my_team_order =
                    response.data.orderInfo.team_prize_total;
                indexDashData.TrackerData.series[0].percent =
                    response.data.orderInfo.percent;

                indexDashData.TopData = response.data.top10Brand; // 排行榜

                indexDashData.statisticsOrder.series[0].effect_tp =
                    response.data.tpInfo.effect_tp; // 已生效
                indexDashData.statisticsOrder.series[0].data =
                    response.data.tpInfo.effect_tp_list.map((item) =>
                        parseFloat(item)
                    ); // 已生效
                indexDashData.statisticsProfit.series[0].no_effect_tp =
                    response.data.tpInfo.no_effect_tp; // 未生效
                indexDashData.statisticsProfit.series[0].data =
                    response.data.tpInfo.not_effect_tp_list.map((item) =>
                        parseFloat(item)
                    ); // 未生效

                indexDashData.statisticsItems[0].title =
                    response.data.tpInfo.baseshop_tp + "";
                this.superbase_tp = response.data.tpInfo.superbase_tp + "";

                indexDashData.sessionsData.series[0].data =
                    response.data.activeUserInfo.active_num; // 活跃
                indexDashData.sessionsData.series[0].no_data =
                    response.data.activeUserInfo.not_active_num; // 不活跃
                indexDashData.sessionsData.series[0].total =
                    response.data.activeUserInfo.total_num; // 综合活跃数
                indexDashData.sessionsData.series[0].list[0].data[0] =
                    response.data.activeUserInfo.active_num;
                indexDashData.sessionsData.series[0].list[0].data[1] =
                    response.data.activeUserInfo.not_active_num;
                // console.log(response.data.userInfo,'response.data.userInfo')

                localStorage.setItem(
                    "userDatas",
                    JSON.stringify(response.data.userInfo)
                );

                this.data.congratulations = response.data.userInfo;
                indexDashData.congratulations = response.data.userInfo; // 用户信息等级信息
                indexDashData.earningsChart.data.allTotal =
                    response.data.moneyInfo.the_month_total; // 月收入佣金
                indexDashData.earningsChart.data.percent =
                    response.data.moneyInfo.percent; // 月收入佣金百分比

                indexDashData.todayBirthUser = response.data.todayBirthUser; // 今日生日用户
                indexDashData.userRankInfo = response.data.userRankInfo; // 会员排行榜

                // 总计
                // indexDashData.statisticsItems[0].title =
                //   response.data.allTotal.team_count; // 团队数量
                indexDashData.members[0].title =
                    indexDashData.sessionsData.series[0].no_data;
                indexDashData.members[1].title =
                    indexDashData.sessionsData.series[0].data;
                indexDashData.statisticsItems[5].title =
                    indexDashData.sessionsData.series[0].total; // 订单数量
                indexDashData.statisticsItems[3].title =
                    response.data.allTotal.order_count; // 订单数量
                indexDashData.statisticsItems[2].title =
                    response.data.allTotal.tp_count; // 总提交 TP

                // 三圈百分比
                indexDashData.threeCircle.series[0] = Number(
                    response.data.threeCircle.personal.replace("%", "")
                );
                indexDashData.threeCircle.series[1] = Number(
                    response.data.threeCircle.level.replace("%", "")
                );
                indexDashData.threeCircle.series[2] = Number(
                    response.data.threeCircle.team.replace("%", "")
                );
                // 用户信息
                indexDashData.userInfo = response.data.userInfo;
              });
          this.data = indexDashData;
          this.getComission();
        }, 0);

        this.getTpsdata();
      });
    });
  },
  methods: {
    kFormatter,
    getComission(cate) {
      axios.get("/index/get-comission?cate=" + cate).then((res) => {
        this.data.statisticsItems[1].title = res?.data?.paid_commission;
        this.data.statisticsItems[4].title = res?.data?.unpaid_commissions;
        // this.ya = res?.data?.paid_commission
        // this.yb = res?.data?.unpaid_commissions
      });
    },
    getTpsdata(cate) {
      axios.get("/index/tp-sdata?cate=" + cate).then((res) => {
        this.tpDData = [
          { value: res?.data?.pending_tp, name: "Pending TP" },
          { value: res?.data?.inforce_tp, name: "Inforce TP" },
        ];

        // this.allTotal = res?.data?.pending_tp + res?.data?.inforce_tp;
        this.allTotal = addStr(res?.data?.pending_tp , res?.data?.inforce_tp);
      });
    },
    toPage(name) {
      this.$router.push({
        name: name,
      });
    },
    tanchuang() {
      this.$bvModal.show("audit-modal");
    },
    inputImageRenderer(e) {
      // console.log(e)
      const files = e.files[0];
      const formData = new FormData();
      formData.append("image", files);
      axios.post("/upload/uploadAvatar", formData).then((res) => {
        this.imgurl =
            process.env.VUE_APP_BASE_URL.replace("/backend", "/") + res.data.url;
      });
    },
    submitChange() {
      axios
          .post("user/loginEditInfo", {
            password_hash: this.password_hash,
            gender: this.gender.value,
            address: this.address,
            avatar: this.imgurl,
            real_name: this.real_name,
          })
          .then((res) => {
            if (res.code <= 0) {
              this.$bvToast.toast(res.msg, {
                autoHideDelay: 2000,
                appendToast: true,
                autoHide: true,
                toastClass: "bg-success text-white",
                title: "Editing Success",
              });
            } else {
              this.$bvToast.toast(res.msg, {
                autoHideDelay: 2000,
                appendToast: true,
                autoHide: true,
                toastClass: "bg-danger text-white",
                title: "Edit Fail",
              });
            }
          })
          .catch((err) => {
            this.$bvToast.toast(err.msg || "Edit Fail", {
              autoHideDelay: 2000,
              appendToast: true,
              autoHide: true,
              toastClass: "bg-danger text-white",
            });
          });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.dashboard_active:hover {
  color: #445f79;
  cursor: pointer;
}
</style>
