<template>
  <b-card no-body>
    <b-card-body class="pb-0">
      <b-card-header style="display: flex; flex-direction: row-reverse; padding: 0">
        <b-dropdown
          variant="link"
          toggle-class="p-0"
          no-caret
          right
        >
          <template #button-content>
            <feather-icon
              class="text-body"
              icon="MoreVerticalIcon"
            />
          </template>
          <b-dropdown-item @click="changetime(1)">
            Last 7 Days
          </b-dropdown-item>
          <b-dropdown-item @click="changetime(1)">
            Last Month
          </b-dropdown-item>
          <b-dropdown-item @click="changetime(1)">
            Last Year
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>
      <b-avatar
        class="mb-1"
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder" @click="toPage('vip_Commission_record')">
          {{ statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
      </div>
    </b-card-body>

    <vue-apex-charts
      type="area"
      height="100"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData"
    />
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BAvatar } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { areaChartOptions } from './chartOptions'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BCardHeader,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
      default: '',
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions))
        options.theme.monochrome.color = $themeColors[this.color]
        return options
      }
      return this.chartOptions
    },
  },
  methods: {
    toPage(name) {
      this.$router.push({ name })
    },
    changetime(cate) {
      console.log('statisticType', cate)
    }
  }
}
</script>
