<!-- @format -->

<template>
  <div>
    <b-card title="Scoreboard" v-if="false">
      <template v-if="swiperData.length > 0">
        <div class="container">
          <swiper
              :options="{ ...swiperOptions, slidesPerView }"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
                v-for="item in swiperData"
                :key="item.img"
                class="swiper-slide"
            >
              <template v-if="item.img && item.title">
                <!-- <el-image :src="item.img" style="width: 120px; height: 120px"> -->
                <!-- <template slot="error"> -->
                <div style="width: 100%; height: 100%; text-align: center">
                  <div class="swiper-title">{{ item.title }}</div>
                  <img :src="item.img" class="woccc swiper-img" alt=""/>
                  <!-- </div> -->
                  <!-- </template> -->
                  <!-- </el-image> -->
                  <div class="swiper-name">{{ item.real_name }}</div>
                </div>
              </template>
              <template v-else>
                <div class="swiper-title">{{ item.title }}</div>
                <BAvatar
                    :src="baseAvatar"
                    class="swiper-img"
                    alt="Load failure"
                />
                <div class="swiper-name">No candidate</div>
              </template>
            </swiper-slide>
            <div slot="pagination" class="swiper-pagination"/>
            <div slot="button-next" class="swiper-button-next"/>
            <div slot="button-prev" class="swiper-button-prev"/>
          </swiper>
        </div>
      </template>
      <template v-else>
        <swiper
            class="swiper-autoplay"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide class="swiper-slide">
            <div class="swiper-title">Star TOP Recruiter</div>
            <img :src="baseAvatar" class="swiper-img" alt="Load failure"/>
            <div class="swiper-name">No candidate</div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div class="swiper-title">Star TOP Order</div>
            <img :src="baseAvatar" class="swiper-img" alt="Load failure"/>
            <div class="swiper-name">No candidate</div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div class="swiper-title">Star TOP Leader</div>
            <img :src="baseAvatar" class="swiper-img" alt="Load failure"/>
            <div class="swiper-name">No candidate</div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div class="swiper-title">Star TOP Producer</div>
            <img :src="baseAvatar" class="swiper-img" alt="Load failure"/>
            <div class="swiper-name">No candidate</div>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination"/>
          <div slot="button-next" class="swiper-button-next"/>
          <div slot="button-prev" class="swiper-button-prev"/>
        </swiper>
      </template>

      <template #code>
        {{ codeAutoplay }}
      </template>
    </b-card>
    <div style="margin-bottom: 2rem;">
      <template v-if="dataList.length > 0">
        <swiper
            :options="{ ...swiperOptions, slidesPerView }"
            style="width: 100%;"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide
              v-for="item in dataList"
              :key="item"
              class="swiper-slide"
          >
            <template v-if="item">
              <!-- <el-image :src="item.img" style="width: 120px; height: 120px"> -->
              <!-- <template slot="error"> -->
              <div style="width: 100%; height: 100%; text-align: center">
                <img :src="item.image" class="woccc swiper-img" alt=""
                     style="width: 100%;height: 267px;object-fit: cover;"
                />
                <!-- </div> -->
                <!-- </template> -->
                <!-- </el-image> -->
              </div>
            </template>
            <template v-else>
              <div class="swiper-title">{{ item.title }}</div>
              <BAvatar
                  :src="baseAvatar"
                  class="swiper-img"
                  alt="Load failure"
              />
              <div class="swiper-name">No candidate</div>
            </template>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination"/>
          <div slot="button-next" class="swiper-button-next"/>
          <div slot="button-prev" class="swiper-button-prev"/>
        </swiper>
      </template>
      <template v-else>
        <swiper
            class="swiper-autoplay"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide class="swiper-slide">
            <div class="swiper-title">Star TOP Recruiter</div>
            <img :src="baseAvatar" class="swiper-img" alt="Load failure"/>
            <div class="swiper-name">No candidate</div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div class="swiper-title">Star TOP Order</div>
            <img :src="baseAvatar" class="swiper-img" alt="Load failure"/>
            <div class="swiper-name">No candidate</div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div class="swiper-title">Star TOP Leader</div>
            <img :src="baseAvatar" class="swiper-img" alt="Load failure"/>
            <div class="swiper-name">No candidate</div>
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <div class="swiper-title">Star TOP Producer</div>
            <img :src="baseAvatar" class="swiper-img" alt="Load failure"/>
            <div class="swiper-name">No candidate</div>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination"/>
          <div slot="button-next" class="swiper-button-next"/>
          <div slot="button-prev" class="swiper-button-prev"/>
        </swiper>
      </template>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg, BCard, BAvatar } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import { codeAutoplay } from './code'
import axios from '@/libs/axios'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BCard,
    BAvatar,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      // defaultImage: `${process.env.VUE_APP_COM_URL}/static/default-avatar.png`,
      codeAutoplay,
      // slidesPerView: 3,
      slidesPerView: 1,
      swiperOptions: {
        // slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: false,
        // },
        pagination: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperData: [],
      baseAvatar: require('@/assets/avatar.svg'),
      dataList: [],
    }
  },
  watch: {
    data: {
      handler(val) {
        this.formatData(val)
      },
      deep: true,
    },
  },
  created() {
    const ww = document.body.clientWidth
    if (ww > 576) {
      // this.slidesPerView = 3
      this.slidesPerView = 1
    } else {
      this.slidesPerView = 1
    }
    this.getBirthdayList()
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.formatData(this.data)
        this.$forceUpdate()
      }, 2000)
    })
  },
  methods: {
    formatData(data) {
      if (!data) return
      const endData = []
      const val = {
        team_star: {
          user_id: null,
          money: null,
          member2: null,
        },
        yeji_star: {
          id: 51,
          // real_name: '张三12',
          avatar: this.baseAvatar,
          all_tp: '15000.00',
        },
        order_star: {
          user_id: 56,
          total: 3,
          member: {
            id: 56,
            // real_name: '张三',
            // avatar: 'https://myonewow.com/MasterPage/images/profile/pic1.jpg',
            avatar: this.baseAvatar,
          },
        },
        invite_star: {
          id: '',
          // real_name: 'LiPeiran',
          avatar: this.baseAvatar,
          child_num: 20,
        },
      }

      endData[0] = {
        img: data?.invite_star?.avatar || val?.invite_star?.avatar,
        title: 'Star TOP Recruiter',
        real_name: data?.invite_star?.real_name || '',
      }

      endData[1] = {
        img:
            data?.order_star?.member?.avatar || val?.order_star?.member?.avatar,
        title: 'Star TOP Order',
        real_name: data?.order_star?.member?.real_name || '',
      }

      // if (data?.team_star?.member2?.avatar || data?.team_star?.member2?.user_id) {
      endData[2] = {
        img:
            data?.team_star?.member2?.avatar || val?.team_star?.member2?.avatar,
        title: 'Star TOP Leader',
        real_name: data?.team_star?.member2?.real_name || '',
      }
      // }

      endData[3] = {
        img: data?.yeji_star?.avatar || val?.yeji_star?.avatar,
        title: 'Star TOP Producer',
        real_name: data?.yeji_star?.real_name || '',
      }
      this.swiperData = endData
    },
    getBirthdayList() {
      axios.get('/birthday/list')
          .then(response => {
            this.dataList = response.data.list || []
          })
          .catch(res => {
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.xieburo {
  .b-avatar-custom {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff !important;
  }
}

img.woccc {
  display: inline-block;
  transform: scale(1);
}

img.woccc::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 120px;
  background: #fff url("../../assets/avatar.svg") no-repeat center;
  background-size: contain;
  color: transparent;
}

// img.woccc::after {
//   content: '';
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   bottom: 0;
//   width: 120px;
//   height: 120px;
//   line-height: 2;
//   background-color: rgba(0, 0, 0, 0.5);
//   color: white;
//   font-size: 12px;
//   text-align: center;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
.swiper-name {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000;
}

.swiper-title {
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 4px;
  color: rgba(255, 159, 67, 0.85);
}

.swiper-img {
  width: 120px;
  height: 120px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
