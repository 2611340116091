var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticStyle:{"padding":"10px"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"12"}},[_c('b2',{attrs:{"data":_vm.data.userRankInfo}})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"6","md":"6","sm":"12"}},[_c('l1',{attrs:{"switchOn":_vm.switchOn}})],1),_c('b-col',{attrs:{"xl":"6","md":"6","sm":"12"}},[_c('l2',{attrs:{"switchOn":_vm.switchOn,"data":_vm.data.statisticsItems}})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"12","sm":"12"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"5","sm":"12"}},[_c('b-card',[_c('b-card-header',{staticStyle:{"display":"flex","flex-direction":"row-reverse","padding":"0"}}),_c('div',{staticClass:"pie-text text-center"},[_c('h2',{staticClass:"font-weight-bolder dashboard_active",on:{"click":function($event){return _vm.toPage('vip_Commission_record')}}},[_vm._v(" "+_vm._s(_vm.switchOn?'xxx':_vm.allTotal ? ("$" + (String(_vm.allTotal).replace( /\B(?=(\d{3})+(?!\d))/g, "," ))) : _vm.allTotal)+" ")]),_c('span',{staticClass:"font-weight-bold dashboard_active"},[_vm._v("All Total")])]),_c('div',{staticStyle:{"height":"300px"}},[(_vm.tpDData.length)?_c('AppEchartDoughnut',{attrs:{"isJump":true,"series":[
                  {
                    name: 'Visit source',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                      show: true,
                    },
                    labelLine: {
                      show: true,
                    },
                    data: _vm.tpDData,
                  } ]}}):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"lg":"7","md":"12"}},[(_vm.data.TrackerData.series[0].percent)?_c('AnalyticsAvgSessionsTracker',{attrs:{"info":_vm.data.TrackerData,"switchOn":_vm.switchOn}}):_vm._e()],1)],1)],1)],1),_c('b-row',{staticClass:"match-height",attrs:{"lg":"12"}},[_c('b-col',{attrs:{"lg":"8","md":"8","xl":"8"}},[_c('ecommerce-member',{attrs:{"data":_vm.data.todayBirthUser}})],1),_c('b-col',{attrs:{"lg":"4","md":"4","xl":"4"}},[_c('l3',{attrs:{"switchOn":_vm.switchOn,"data":_vm.data.members}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }