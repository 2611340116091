<template>
  <b-card no-body>
    <b-card-body class="pb-0">
      <b-card-header style="display: flex; flex-direction: row-reverse; padding: 0">
        <b-dropdown
          variant="link"
          toggle-class="p-0"
          no-caret
          right
        >
          <template #button-content>
            <feather-icon
              class="text-body"
              icon="MoreVerticalIcon"
            />
          </template>
          <b-dropdown-item @click="changetime(1)">
            Last 7 Days
          </b-dropdown-item>
          <b-dropdown-item @click="changetime(1)">
            Last Month
          </b-dropdown-item>
          <b-dropdown-item @click="changetime(1)">
            Last Year
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>
      <b-avatar
        class="mb-1"
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder" @click="toPage('vip_Commission_record')">
          {{ statistic ? `${String(statistic).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
      </div>
    </b-card-body>

    <vue-apex-charts
      height="100"
      width="100%"
      :options="statisticsOrder.chartOptions"
      :series="chartData"
    />
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BAvatar } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardBody,
    BCardHeader,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
      default: '',
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      statisticsOrder: {
        series: [
          {
            name: '2020',
            data: [45, 85, 65, 45, 65],
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  methods: {
    toPage(name) {
      this.$router.push({ name })
    },
    changetime(cate) {
      console.log('statisticType', cate)
    }
  }
}
</script>
