<template>
  <b-card class="card-congratulation-medal">
    <h2 style="color:#000000">
      <strong>Welcome! 🎉 {{ data.real_name }}</strong
      >!
    </h2>
    <b-card-text class="font-small-3" >
      <h3 style="color: #495f77;">Your current level is {{ data.level.name }} !</h3>
    </b-card-text>
    <!--<h3 class="mb-75 mt-2 pt-50">Still short of the next level</h3>-->
    <!-- <h3 class="mb-75 mt-2">
      <b-link> {{ switchOn?'xxx':absFormatter(data.next_level_info.needTp) }} </b-link>TP
    </h3>-->
    <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        to="/dashboard/level_info"
    >
      Level Description
    </b-button> -->
    <div class="congratulation-medal" style="width: 20%;display: flex;flex-flow: column;align-items: center;">
      <b-img
          :src="require('@/assets/images/illustration/badge.svg')"
          alt="Medal Pic"
          style="width: 97%"
      />
      <div style="margin-top: 10px; font-size: 18px; cursor: pointer;" @click="toDetail">
        Learn More >>
      </div>
    </div>

    <b-img
        :src="path"
        alt="Medal Pic"
        style="width: 28%; height: auto; display: block; margin: 0 auto; margin-top: 10px"
    />
  </b-card>
</template>

<script>
import { BCard, BCardText, BLink, BButton, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { absFormatter } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    switchOn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        next_level_info: {
          needTp: 0,
        },
        level: {
          name: "",
        },
      },
      path: "",
    };
  },
  mounted() {
    // console.log(this, 'aaaa')
    setTimeout(() => {
      this.data = JSON.parse(localStorage.getItem("userDatas"));
    }, 1000);
    this.$nextTick(() => {
      axiosIns({
        url: "user/userinfo",
        method: "get",
      }).then((res) => {
        const { code_url } = res.data;

        this.path = code_url;
      });
    });
  },
  methods: {
    absFormatter,
    toDetail(){
      this.$router.push({
        path:"/dashboard/level_info"
      })
    }
  },
};
</script>
<style lang="scss">
.congratulation-medal1 {
  position: absolute;
  bottom: 21px;
  right: 135px;
  width: 95px;
}

.button-right-bottom {
  position: fixed;
  right: 20px;
}
</style>
